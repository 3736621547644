import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Layout/Seo"

import { Feature } from "../components/Components/Features/Features"
import { PageTitle } from "../components/Components/Headings/PageTitle"
import { services } from "../data/services"
import CTA from "../components/Components/Content/CTA"

const ServicesPage = ({ data }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [data])

  return (
    <Layout>
      <Seo
        title="Kinzica Studio | Our services"
        description="Kinzica Studio - Composition | Mix/Mastering | Sonic Branding | Sound Design | Music supervision"
        url="www.kinzicastudio.com/services"
        keywords={[
          "Kinzica Studio Services",
          "Kinzica Studio Composition",
          "Kinzica studio Mix/Mastering",
          "Kinzica Studio Sonic Branding",
          "Kinzica Studio Sound Design",
          "Kinzica studio Music supervision",
        ]}
      />
      <PageTitle id="services" title="Our strategy" subtitle="" />
      <p className="lg:leading-relaxed leading-10 text-center lg:w-2/3 md:w-4/5 w-full mx-auto px-4 text-gray-300 text-xl lg:text-2xl font-mont font-extralight">
        The secret for a successful soundtrack is communication and passion for
        music.
        <br />
        That is why we take the time to discuss our client's point of view.
      </p>
      <p className="lg:leading-relaxed leading-10 text-center lg:w-2/3 md:w-4/5 w-full mx-auto px-4 text-gray-300 text-xl lg:text-2xl font-mont font-extralight">
        We develop together what will become the music and sound for your own
        video.
        <br />
        Our in-house team will take care of every step of the production from
        start to finish helping you to find the best way to make music work for
        your objectives.
      </p>

      <div className="lg:w-1/2 w-full mx-auto px-12 my-24">
        <p className="mt-4 border-b-white border-b  w-full "></p>
      </div>

      <Feature services={services} />
      <div className="lg:w-1/2 w-full mx-auto px-12 my-24">
        <p className="mt-4 border-b-white border-b  w-full "></p>
      </div>
      <CTA
        title={"Interested in working with us?"}
        text={`Contact us and let us know what you want to build together. We offer full support wether you want an audio track for your commercial or deliver your next album`}
        buttonText={"Contact us"}
        link={"/contact"}
      />
    </Layout>
  )
}

export default ServicesPage
