import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx';


export const Feature = ({ services }) => {
  return (
    <div className="mt-24">
      <div className="flex flex-col">
        {
          services.map((s, i) => (

            <div key={s.id} className={clsx(`mx-auto my-6 text-center col-span-1 lg:w-2/3 md:w-3/4 w-full`)}>
              <div className="flex items-center justify-center w-16 h-16 mb-4 mx-auto">
                <FontAwesomeIcon icon={s.icon} className="w-12 h-12 text-kinzica-pink" />
              </div>
              <h6 className="lg:text-5xl text-3xl mb-4 text-white font-bold">{s.title}</h6>
              <p className="lg:leading-relaxed leading-relaxed font-mont font-extralight text-xl lg:text-2xl lg:px-24 px-4 mb-3 text-white">{s.text}</p>
            </div>
          ))



        }
      </div>
    </div>
  );
};