
import { sonicBranding } from '../svg/SonicBranding.svg';
import { faMusic } from '@fortawesome/free-solid-svg-icons'


export const services = [
  {
    id: "9cd61ead-96e6-4f9e-bfcc-632209efcc32",
    title: "Music Composition",
    value: "composition",
    icon: faMusic,
    text: "We provide a wide range of composition styles, from fancy orchestral tunes to groovie funk or punching hybrid electronic music. And if you already like a specific song, we can help you to license it or create a ‘Sound-alike for you.",
    link: ""
  },
  {
    id: "cfc21ced-cbd9-45bb-9d39-578f521ab9fc",
    title: "Sound Design",
    value: "sound-design",
    icon: faMusic,
    text: "It is not always about the music. Our Sound Design team can help you to create immersive sound for your video, crafting ear catching sounds and natural sounding foleys from scratch.",
    link: ""
  },
  {
    id: "b4a6f52b-b37d-4864-9ef8-0d5844f051af",
    title: "Recording and Mixing",
    value: "mix-master",
    icon: faMusic,
    text: "Like a good recipe, the secret for a good production is quality of the ingredients and consistency. We always work within the same team of people when it comes to recording and Mixing. We have access to a wide range of musicians and sound engineers, each one of them well specialized and able to deliver industry top standard quality music.",
    link: ""
  },
]