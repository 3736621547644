import React from 'react'

const CTA = ({ title, text, buttonText, link }) => {
  return (
    <div className="pb-24 flex flex-col lg:items-center items-center px-12 mx-auto lg:w-1/2 w-full">
      <h1 className='lg:text-start text-center text-6xl text-kinzica-pink'>{title}</h1>
      <p className='lg:text-start text-center text-2xl font-thin font-mont text-white my-2'>{text}</p>
      <a href={link}>
        <button className='my-4 bg-kinzica-pink text-2xl py-3 px-8 hover:opacity-95 hover:text-white rounded'>{buttonText}</button>
      </a>
    </div>
  )
}

export default CTA